/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        //acessibilidade 
        $('[data-action=fontUp]').click(function(){
          $('html').addClass('font-up');
        });
        $('[data-action=fontDown]').click(function(){
          $('html').removeClass('font-up');
        });
        var siteStyle = $('[id="sage/css-css"]');
        $('[data-action=toggleContrast]').click(function (){
          if(siteStyle.attr('data-contrast') === 'true'){
            siteStyle.attr('data-contrast','false');
            siteStyle.attr('href','/app/themes/sage/dist/styles/main.css');
          }else{
            siteStyle.attr('data-contrast','true');
            siteStyle.attr('href','/app/themes/sage/dist/styles/main-contraste.css');
          }
          
       });

        //print section
        $('.jPrint').click(function(){
          var prtContent = $('.jPrint').parents('section')[0];
          var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
          WinPrint.document.write('<html><head><link rel="stylesheet" type="text/css" href="/app/themes/sage/dist/styles/main.css"></head><body class="print">');
          WinPrint.document.write(prtContent.innerHTML);
          WinPrint.document.write('</body></html>');
          WinPrint.document.close();
          
          setTimeout(function(){
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        });


        // Add smooth scrolling to all links
        //not("[data-toggle]") disable to accordeon anchors
        $("a").not("[data-toggle]").on('click', function(event) {
          if (this.hash !== "") {
            // event.preventDefault();
            var hash = this.hash;
            var offset = $(hash).offset();
            if(offset){
              $('html, body').animate({
                scrollTop: $(hash).offset().top
              }, 800, function(){
                window.location.hash = hash;
              });
            }
            
          } 
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery("body").click(function(e) {
          if(e.target.className !== ".menu-container div.search .search-input" && e.target.className !== ".menu-container div.search .search-submit") {
            jQuery(".menu-container div.search .search-input").removeClass('seach--open');
            jQuery('.menu-container div.search .search-input').val('');
          }
        });
        jQuery('.menu-container div.search .search-input').keydown(function() {
          if(jQuery('.menu-container div.search .search-input').val()) {
            jQuery('.menu-container div.search .search-input').addClass('seach--open');
          } else {
            jQuery('.menu-container div.search .search-input').removeClass('seach--open');          
          }
        });

        jQuery(".videoLink").click(function() {
            var videoId = jQuery(this).attr('data-id');
            var ifrm = jQuery("#iframeVideo");
            ifrm.attr("src", "https://www.youtube.com/embed/"+ videoId +"?showinfo=0&rel=0");
            jQuery('#modalDepoimento').modal('show');
            $('#modalDepoimento').on('hidden.bs.modal', function (e) {
              ifrm.attr("src", "");
            });
        }); 

        /*
        * Script para enviar parâmetros do formulário para GA através de pageview
        * TASK 475606479289112/560777975454223
        * PARAM/URL: email / conversao_email
        */
        document.addEventListener( 'wpcf7submit', function( event ) {
          var inputs = event.detail.inputs;
          for ( var i = 0; i < inputs.length; i++ ) {
            if ( 'email' == inputs[i].name ) {
              var newUrl = window.location.pathname + "?conversao_email=";
              newUrl += inputs[i].value;
              ga('send', {
                hitType: 'pageview',
                page: newUrl
              });
              break;
            }
          }
        }, false );
      }
    },
    // Home page
    'home': {
      init: function() {
        $( ".video-head .video-float .image-animation" ).animate({
          opacity: 1,
          width: "206px",
        }, 2000);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        //Efeitos na home
        $(window).scroll(function() {
          cursosEffects();
        });
        var cursosEffects = function(){
          var menuTop = $(window).scrollTop();
          if(menuTop >= 768){
            $("#main-menu").addClass("fixed");
          }else{
            $("#main-menu").removeClass("fixed");
          }
          // var hT = $('.home-cursos').offset().top,
          // hH = $('.home-cursos').outerHeight(),
          // wH = $(window).height(),
          // wS = $(this).scrollTop(),
          // scrolledFade = false,
          // scrolledBox = false;
          // if (wS + 280 > (hT+hH-wH) && !scrolledFade){
          //   $('.home-cursos').removeClass("to-fade");
          //   $('.home-cursos').addClass("fade-in-slow");
          //   scrolledFade = true;
          // }
          // if (wS + 100 > (hT+hH-wH) && !scrolledBox){ 
          //   $(".home-cursos .box").removeClass('to-fade')
          //   .addClass('fade-in');

          //   $(".home-cursos .box").removeClass('to-fade')
          //   .addClass('fade-in');
          //   scrolledBox = true;
          // }
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'seletivo_simplificado': {
      init: function() {
        
        var wpcf7Elm = document.querySelector( '#wpcf7-f5251-p134-o1' ); 
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
          jQuery('#modalCursoInscricaoSimplificado').modal('hide')
        }, false );
      }
    },
    'biblioteca': {
      init: function() {
        var values = [];
        $("#conversorRangeList option").each(function(i,e){
          values[values.length] = {ponto:$(this).attr('ponto'),valor:$(this).val()}
        });
        $('#conversorRange').attr('max',values.length-1);
        var changePontos = function(){
          $('#conversorRangePonto').html(values[$('#conversorRange').val()].ponto);
          $('#conversorRangeValor').html(values[$('#conversorRange').val()].valor);
        }
        changePontos();
        $('#conversorRange').change(function(){
          changePontos();
        });
      }
    },
    'post_type_archive_curso': {
      init: function() {
        filterCursoCategory();
      }
    },
    'tax_cursos_category': {
      init: function() {
        filterCursoCategory();
      }
    },
    'vestibular': {
      init: function() {
        
      }
    },
    'homenageados': {
      finalize: function() {
        jQuery('.btn-more').click(function(){
          jQuery(this).parent('.card-block').toggleClass('opened');
        });
        $('.btn-more').toggle(function() {
          $(this).text('LER MENOS');
        }, function() {
            $(this).text('LER TUDO');
          });
      }
    },
    'historia': {
      finalize: function() {
        var timelineBlocks = jQuery('.cd-timeline-block'),
        offset = 0.8;
    
        //hide timeline blocks which are outside the viewport
        hideBlocks(timelineBlocks, offset);
      
        //on scolling, show/animate timeline blocks when enter the viewport
        jQuery(window).on('scroll', function(){
          (!window.requestAnimationFrame) 
            ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
            : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
        });
      
        function hideBlocks(blocks, offset) {
          blocks.each(function(){
            ( jQuery(this).offset().top > jQuery(window).scrollTop()+jQuery(window).height()*offset ) && jQuery(this).find('.cd-timeline-img, .cd-timeline-content').addClass('is-hidden');
          });
        }
      
        function showBlocks(blocks, offset) {
          blocks.each(function(){
            ( jQuery(this).offset().top <= jQuery(window).scrollTop()+jQuery(window).height()*offset && jQuery(this).find('.cd-timeline-img').hasClass('is-hidden') ) && jQuery(this).find('.cd-timeline-img, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
          });
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function filterCursoCategory(){
    
    $('.curso-category-list input').change(function(){
      var $this = $(this);
      $this.parents('.curso-category-list').find('.curso-category-list__item').hide();
      $this.parents('.curso-category-list').find('.curso-category-list__item.'+$this.val()).show();
      
    });
  }
})(jQuery);
